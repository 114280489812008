import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import {
  OccasionStatusFilterType,
  OccasionType
} from '@aurora/shared-generated/types/graphql-schema-types';
import { enumFromValue } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import messagesCountQuery from '@aurora/shared-client/components/messages/MessagesCount.query.graphql';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { MessageConstraints } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  MessagesCountQuery,
  MessagesCountQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { OccasionTypeDropdownItem } from './types';

interface Props {
  /**
   * The occasion type item to display
   */
  item: OccasionTypeDropdownItem;
  /**
   * Whether this item is the active selected item
   */
  active: boolean;
  /**
   * Callback function when the item is selected.
   *
   * @callback
   */
  onOccasionTypeSelected?: (item: OccasionTypeDropdownItem) => void;
  /**
   * The occasion status tab
   */
  occasionStatusTab: string;
}

/**
 * The occasion type filter dropdown item component
 *
 * @author Be.Abhijith
 */
const OccasionTypeFilterDropdownItem = ({
  item,
  active,
  onOccasionTypeSelected,
  occasionStatusTab
}: Props): React.ReactElement => {
  const { contextNode } = useContext(AppContext);

  const constraints: MessageConstraints = {
    boardId: { eq: contextNode.id },
    ...(item.statusKey !== 'ALL' && {
      occasionType: { eq: enumFromValue(OccasionType, item.statusKey) }
    }),
    ...(occasionStatusTab && {
      occasionStatus: {
        in: [
          occasionStatusTab as OccasionStatusFilterType,
          ...(occasionStatusTab === OccasionStatusFilterType.Upcoming
            ? [OccasionStatusFilterType.Ongoing]
            : [])
        ]
      }
    }),
    depth: { eq: 0 }
  };
  const { data: messageCountResult, loading: messageCountLoading } = useQueryWithTracing<
    MessagesCountQuery,
    MessagesCountQueryVariables
  >(module, messagesCountQuery, {
    variables: {
      constraints
    }
  });

  if (messageCountLoading) {
    return null;
  }

  const isDisabled: boolean = item.statusKey !== 'ALL' && messageCountResult.messagesCount === 0;

  return (
    <Dropdown.Item
      active={active}
      key={item.statusKey}
      onSelect={(): void => onOccasionTypeSelected(item)}
      disabled={isDisabled}
    >
      <span aria-label={item.text}>{item.text}</span>
    </Dropdown.Item>
  );
};

export default OccasionTypeFilterDropdownItem;
